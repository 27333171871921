
//日期转字符串格式(yyyy-MM-dd HH:mm:ss)
export function DateToStr(date) {
    if (date == null) {
        date == new Date();
    }
    var year = date.getFullYear();//年
    var month = date.getMonth();//月
    var day = date.getDate();//日
    var hours = date.getHours();//时
    var min = date.getMinutes();//分
    var second = date.getSeconds();//秒
    return year + "-" +
        ((month + 1) > 9 ? (month + 1) : "0" + (month + 1)) + "-" +
        (day > 9 ? day : ("0" + day)) + " " +
        (hours > 9 ? hours : ("0" + hours)) + ":" +
        (min > 9 ? min : ("0" + min)) + ":" +
        (second > 9 ? second : ("0" + second));
}

//日期转字符串格式(yyyy-MM-dd HH:mm:ss)
export function DateToMMddHHmm(date) {
    if (date == null) {
        date == new Date();
    }
    var month = date.getMonth();//月
    var day = date.getDate();//日
    var hours = date.getHours();//时
    var min = date.getMinutes();//分
    return ((month + 1) > 9 ? (month + 1) : "0" + (month + 1)) + "-" +
        (day > 9 ? day : ("0" + day)) + " " +
        (hours > 9 ? hours : ("0" + hours)) + ":" +
        (min > 9 ? min : ("0" + min));
}

//日期转字符串格式(HHmm)
export function DateToHHmm(date) {
    if (date == null) {
        date == new Date();
    }
    var hours = date.getHours();//时
    var min = date.getMinutes();//分
    return (hours > 9 ? hours : ("0" + hours)) + ":" + (min > 9 ? min : ("0" + min));
}

//日期转字符串格式(yyyyMMdd)
export function DateToYYYYYMMDD(date) {
    if (date == null) {
        date == new Date();
    }
    var year = date.getFullYear();//年
    var month = date.getMonth();//月
    var day = date.getDate();//日
    return year + "-" + ((month + 1) > 9 ? (month + 1) : "0" + (month + 1)) + "-" + (day > 9 ? day : ("0" + day));
}

//字符串转日期
export function StrToDate(datestr) {
    return new Date(datestr);
}

export function formatDate(date) {
    return date.substring(0, 10);
}

function* gen(nodes = [], id, children, name) {

    for (let item of nodes) {
        if (item.id == id) {
            yield item[name]
        }
        if (item[children] && item[children].length) yield* gen(item[children], id, children, name)
    }

}


//回显el-cascader文本
// datas原始数据，value选中数据数组，children子结构名称，name显示文本
export function selectCascaderLabel(datas, value, child, na) {
    var children, name;
    if (value === undefined) {
        return "";
    }
    if (typeof (datas) === typeof ({})) {
        datas = [datas];
    }
    if (child === undefined) {
        children = 'children'
    } else {
        children = child;
    }
    if (na === undefined) {
        name = 'name'
    } else {
        name = na;
    }
    var text = [];
    for (let index = 0; index < value.length; index++) {
        const element = value[index];


        text.push(...gen(datas, element, children, name))

    }

    return text;

}

//千分位数字格式化
export function formatNumberWithCommas(number) {
    number += '';
    let [integer, decimal] = number.split('.');
    const doSplit = (num, isInteger = true) => {
        if (num === '') return '';
        if (isInteger) num = num.split('').reverse();
        let str = [];
        for (let i = 0; i < num.length; i++) {
            if (i !== 0 && i % 3 === 0) str.push(',');
            str.push(num[i]);
        }
        if (isInteger) return str.reverse().join('');
        return str.join('');
    };
    integer = doSplit(integer);
    if (decimal != undefined) {
        decimal = doSplit(decimal, false);
    }
    return integer + ((decimal === '' || decimal === undefined) ? '' : '.' + decimal);
}

/**
 * 获取数据分组
 * @param list
 * @param name
 * @returns {*}
 */
export function handleGroupData(list, name) {
    const result = {};
    list.forEach((item) => {
        const attribute = item[name];
        if (!result[attribute]) {

            result[attribute] = [];
        }
        result[attribute].push(item);
    });
    return result;
}


